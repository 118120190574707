<template>
    <div class="home">
        <div class="topbg-container"></div>
        <div class="content">
            <div class="container">
                <div class="promo-block">
                    <u-animate-container>
                        <u-animate
                            name="animate__fadeIn"
                            delay="0s"
                            duration="3s"
                            :iteration="1"
                            :offset="0"
                            animateClass="animated"
                            :begin="false"
                        >
                            <div class="greatray-promo-logo"><p>GreatRay</p></div>
                            <div class="greatray-promo-text"><p>Это история о том, — как жизнь угасает и расцветает
                                вновь,<br>
                                но уже
                                где-то там, — в новом, непредсказуемом мире.<br>Строительство продолжается.*</p></div>
                            <div class="greatray-promo-buttons-block">
                                <div class="download-button"><a
                                    href="https://api.greatray.ru/api/launcher/files/launcher/GreatRay.zip">Скачать
                                    лаунчер</a></div>
                                <div class="faq-button"><a @click="$root.redirect('/help')">F.A.Q.</a></div>
                            </div>
                            <div class="greatray-promo-text"><p style="font-size: 0.8em">* всё также без вайпов!</p>
                            </div>
                        </u-animate>
                    </u-animate-container>
                </div>
            </div>

            <div class="servers-block">
                <div class="vertical-container">
                    <u-animate-container>
                        <u-animate
                            name="animate__fadeIn"
                            delay="0s"
                            duration="1s"
                            :iteration="1"
                            :offset="0"
                            animateClass="animated"
                            :begin="false"
                        >
                            <div class="vertical-title">Сервера</div>
                        </u-animate>
                    </u-animate-container>
                    <u-animate-container>
                        <u-animate
                            name="animate__fadeIn"
                            delay="0s"
                            duration="1s"
                            :iteration="1"
                            :offset="0"
                            animateClass="animated"
                            :begin="false"
                        >
                            <div class="vertical-status-info">
                                <div class="vertical-info-text">Описание серверов проекта и информация о их
                                    состоянии:
                                </div>
                                <div class="vertical-status-info-list">
                                    <ul class="vertical__info_status_ul">
                                        <li>
                                            <div class="circle-green"></div>
                                            - Работает
                                        </li>
                                        <li>
                                            <div class="circle-aqua"></div>
                                            - Перезагрузка
                                        </li>
                                        <li>
                                            <div class="circle-red"></div>
                                            - Ошибка
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </u-animate>
                    </u-animate-container>
                    <u-animate-container>
                        <u-animate
                            name="animate__fadeIn"
                            delay="0s"
                            duration="1.5s"
                            :iteration="1"
                            :offset="200"
                            animateClass="animated"
                            :begin="false"
                        >
                            <div class="vertical-row">

                                <div class="vertical-column" v-for="server in servers">
                                    <div class="vertical-item">
                                        <div class="vertical-item-server-info">
                                            <div :class="[server.icon]">&nbsp</div>
                                            <div class="vertical-item-title-server-name"><p>{{ server.name }}</p></div>
                                            <div :class="{'circle-green':server.query.status === 'ok',
                                     'circle-aqua':server.query.status === 'restart', 'circle-red':server.query.status === 'error'}"></div>
                                            <div class="vertical-item-current-users">
                                                {{
                                                    server.query.status === 'ok' ? server.query.online + " / " +
                                                        server.query.max
                                                        : ""
                                                }}
                                            </div>
                                        </div>
                                        <div class="vertical-item-server-textpromo">{{ server.description }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </u-animate>
                    </u-animate-container>
                </div>
            </div>

            <u-animate-container>
                <u-animate
                    name="animate__fadeIn"
                    delay="0s"
                    duration="2s"
                    :iteration="1"
                    :offset="200"
                    animateClass="animated"
                    :begin="false"
                >
                    <div class="vote-block">
                        <div class="vertical-container">
                            <div class="vertical-title">Голосуй</div>
                            <div class="vertical-info-text">Голосуй и заработай на привилегии и игровые ресурсы!</div>
                            <div class="vertical-vote-row">
                                <div class="vertical-button-vote-link">
                                    <a id="blue" href="http://mcrate.su/project/5846" target="_blank">MC<p>Rate</p></a>
                                </div>
                                <div class="vertical-button-vote-link">
                                    <a id="orange" href="https://mctop.su/servers/4037/" target="_blank">MC <p>TOP</p>
                                    </a>
                                </div>
                                <div class="vertical-button-vote-link">
                                    <a id="yellow" href="https://topcraft.club/servers/7071/" target="_blank"><p>TOP</p>
                                        CRAFT</a>
                                </div>
                            </div>
                        </div>
                    </div>

                </u-animate>
            </u-animate-container>

            <div class="news-block">

                <div class="vertical-container">

                    <div class="vertical-title" id="nws_pdng">
                        <u-animate-container>
                            <u-animate
                                name="animate__fadeIn"
                                delay="0s"
                                duration="2s"
                                :iteration="1"
                                :offset="200"
                                animateClass="animated"
                                :begin="false"
                            >Новости
                            </u-animate>
                        </u-animate-container>
                    </div>
                    <u-animate-container>
                        <u-animate
                            name="animate__fadeIn"
                            delay="0s"
                            duration="2s"
                            :iteration="1"
                            :offset="200"
                            animateClass="animated"
                            :begin="false"
                        >
                            <div class="vertical-info-text">Новые обновления и улучшения GreatRay.
                                <div class="lnk-subscribe"><a>Подписаться на обновления.</a>
                                </div>
                            </div>
                        </u-animate>
                    </u-animate-container>

                    <div class="vertical-news-block" id="news">
                        <div class="v-news-border"></div>
                        <div class="vertical-news" v-for="article in articles">
                            <div class="vertical-news-datetime">
                                <u-animate-container>
                                    <u-animate
                                        name="animate__fadeIn"
                                        delay="0s"
                                        duration="1s"
                                        :iteration="1"
                                        :offset="200"
                                        animateClass="animated"
                                        :begin="false">
                                        {{ datetimeNormalizer(article.created_at) }}
                                    </u-animate>
                                </u-animate-container>
                            </div>
                            <div class="vertical-news-article-data">
                                <div class="vertival-news-article-title">
                                    <u-animate-container>
                                        <u-animate
                                            name="animate__fadeIn"
                                            delay="0s"
                                            duration="1s"
                                            :iteration="1"
                                            :offset="200"
                                            animateClass="animated"
                                            :begin="false">
                                            {{ article.title }}
                                        </u-animate>
                                    </u-animate-container>
                                </div>
                                <u-animate-container>
                                    <u-animate
                                        name="animate__fadeIn"
                                        delay="0s"
                                        duration="1s"
                                        :iteration="1"
                                        :offset="200"
                                        animateClass="animated"
                                        :begin="false"

                                    >

                                        <div class="vertical-news-article-img-wrapper">
                                            <div class="vertical-news-article-img"
                                                 :style="{backgroundImage:`url(${article.image})`}">
                                            </div>
                                        </div>
                                    </u-animate>
                                </u-animate-container>

                                <div class="vertical-news-article-data-preview">
                                    <u-animate-container>
                                        <u-animate
                                            name="animate__fadeIn"
                                            delay="0s"
                                            duration="3s"
                                            :iteration="1"
                                            :offset="100"
                                            animateClass="animated"
                                            :begin="false">
                                            {{ article.text_info }}
                                        </u-animate>
                                    </u-animate-container>
                                </div>
                            </div>
                        </div>
                        <div class="v-news-border"></div>

                        <div class="vertical-news-upload-more" v-if="">
                            <a @click="backRequest_articles()" v-if="this.hasMore">Загрузить
                                ещё</a>
                        </div>

                        <div class="bottom_padding"></div>
                    </div>

                </div>

            </div>
        </div>
    </div>
</template>

<script>
import {UAnimateContainer, UAnimate} from 'vue-wow'

import {TimeFormatterMixin} from "@/mixins/TimeFormatterMixin";
import AuthorizeModal from "../components/modals/AuthorizeModal";

export default {
    name: 'Main',
    mixins: [TimeFormatterMixin],
    props: ['OpeningLinkage'],
    components: {
        UAnimateContainer,
        UAnimate
    },
    metaInfo: {
        title: 'GreatRay ',
        titleTemplate: '%s - всё, что нужно для счастья!',
        htmlAttrs: {
            lang: 'en',
            amp: true
        }
    },

    data() {
        return {
            servers: [],
            articles: [],
            requestTimer: null,
            hasMore: false,
        }
    },
    methods: {
        // Получение информации о серверах
        backRequest_servers() {
            const axios = require('axios');
            axios.get(`/api/server/`).then(
                data => {
                    this.servers = []
                    data.data.forEach(i => {
                        this.servers.push(i)
                    })
                }
            );
        },
        // Получение информации о статьях
        backRequest_articles() {
            const axios = require('axios');
            let url = "/api/article"
            if (this.articles.length > 0)
                url += `?per_page=5&from=${this.articles[this.articles.length - 1].id}`
            else
                url += "?per_page=3"
            axios.get(url).then(
                data => {
                    data.data.data.forEach(i => {
                        this.articles.push(i)
                    })
                    this.hasMore = data.data.total - data.data.to > 0
                }
            )
        },

        openModalRedirect() {
            this.$modal.show(
                AuthorizeModal,
                {'modalRedirect': this.OpeningLinkage},
                {
                    styles: "background: transparent; padding: 0; margin: 0; border-radius: 10px; box-shadow: none;",
                    width: "420px",
                    height: "auto",
                    reset: true
                },
            )
        },

    },

    beforeDestroy() {
        clearInterval(this.requestTimer)
    },

    created() {
        this.backRequest_servers();
        if (this.requestTimer == null) {
            this.requestTimer = setInterval(this.backRequest_servers, 60000);
        }
        this.backRequest_articles();

        if (localStorage.getItem('player')) {
            this.$root.update_PlayerData();
            this.OpeningLinkage === 'forgotpwd' && this.openModalRedirect()
        } else {
            console.log('Вы не авторизованы.')
            if (['register', 'forgotpwd'].includes(this.OpeningLinkage)) this.openModalRedirect()
        }
    },

}
</script>

<style scoped lang="scss">

@import "src/assets/scss/mixins";
@import "src/assets/scss/animations";
@import "src/assets/scss/vars";

.topbg-container {
    position: absolute;
    overflow-x: hidden;
    width: 100%;
    height: 3234px;
    background: url(../assets/img/bgupd_compressed.webp) no-repeat;
    background-size: cover;
    z-index: -1;
}

@media (max-width: 1720px) {
    .topbg-container {
        background-size: contain;
    }
}

.container {
    max-width: 100%;
    margin: 0px auto;
}

.promo-block {
    @include flex-column();
    padding-top: 40vh;
    padding-left: 20vw;
}

.greatray-promo-logo {
    display: flex;
    animation: 5s ease infinite alternate text-shadow-gain-pulse;
    transition: all 0.5s ease;
    -webkit-user-select: none;

    @include text-normalize();

    p {
        font-family: Roboto_medium;
        font-size: 3.5em;
        font-weight: 500;
        font-variant: small-caps;
        color: $not-bright-white;
        margin: 0;
    }
}

.greatray-promo-text {
    display: flex;
    width: auto;
    padding-top: 10px;
    flex-wrap: wrap;

    p {
        margin: 0 0 0 2px;
        color: $dull-gray;
        font-family: $main-font;
        font-size: 0.95em;
    }
}

.greatray-promo-buttons-block {
    display: inline-flex;
    width: 335px;
    padding-top: 20px;
    align-items: center;
    justify-content: space-between;

    .download-button {
        @include button-promo((linear-gradient(0deg,#3b3d90,#5f39c9,#522cb9),linear-gradient(0deg,#1a1339,#5f36cf)));
    }

    .faq-button {
        @include button-promo(radial-gradient(circle at 0 0, #2d2d2d, #383838));
    }

    a {
        @include flexible();
        @include text-normalize-link();
        @include fill-all-block();
        font-family: $main-font;
        font-size: 0.87em;
        text-align: center;
        color: $not-bright-white;
    }
}

.servers-block {
    @include centre-align-with-wrappa-width();
    margin-top: 65vh;
}

.vote-block {
    @include centre-align-with-wrappa-width();
    margin-top: 60px;
}

.news-block {
    @include centre-align-with-wrappa-width();
    margin-top: 35px;
}

.vertical-container {
    @include flexible-column();
}

.vertical-title {
    color: #f7f8f8;
    font-family: $main-font;
    font-size: 2em;
    font-weight: 500;
    @include text-normalize();
    margin-bottom: 5px;
}

.vertical-status-info {
    @include flex-column();
}

.vertical-info-text {
    margin-bottom: 3px;
    font-family: $main-font;
    font-size: 0.85em;
    color: $brightless-gray;
    @include text-normalize();
}

.vertical-status-info-list {
    display: inline-flex;
}

.vertical__info_status_ul {
    @include flexible-row-sp-ev();
    width: 100%;
    list-style: none;
    margin-top: 3px;
    margin-bottom: 5px;

    li {
        font-family: $main-font;
        font-size: 0.85em;
        @include text-normalize();
        color: $brightless-gray;
        margin-left: -30px;
    }
}

@each $class in circle-green, circle-aqua, circle-red {
    $circcolor: white;

    @if $class == circle-green {
        $circcolor: $nice-green
    }
    @if $class == circle-aqua {
        $circcolor: $nice-aqua
    }
    @if $class == circle-red {
        $circcolor: $nice-brightless-red
    }

    .#{$class} {
        @include mini-circle($circcolor)
    }
}

.vertical-row {
    @include flexible-row-wrap();
    margin-top: 10px;
}

.vertical-column {
    @include flex-wrapped();
    flex: 0 1 21.222%;
    height: 110px;
    margin: 10px 10px;
    padding: 20px;

    @include border-radius(20px);
    background-color: $most-dull-gray;

    .vertical-item {
        @include flex-column();
        min-width: 220px;

        .vertical-item-server-info {
            @include flexible-row();
            margin: -15px;
        }

        .vertical-item-title-server-name {
            color: $not-bright-white;
            @include title-1-2();
            @include text-normalize();
            padding: 0 12px;
        }

        .vertical-item-current-users {
            color: $not-bright-white;
            @include title-1-2();
            @include text-normalize();
            padding-left: 12px;
        }

        @each $class in gear, rocket, fire, atomlens, elisiym, mintclever {
            .vertical-item-#{$class}-icon {
                @include icons($class);
            }
        }

        .vertical-item-server-textpromo {
            font-family: AventirN;
            @include flexible();
            @include fill-all-block();
            @include text-normalize();
            @include desctiption-0-8();

            color: $brightless-gray;
            padding-top: 2px;
            text-align: center;
        }
    }
}

.vertical-vote-row {
    @include flexible-row-sp-ev();
    margin-top: 10px;
    margin-left: 10px;
}

@include vote-btn();

#nws_pdng {
    margin-top: 60px;
    margin-bottom: 5px;
}

.lnk-subscribe {
    @include flexible();
    margin-top: 10px;

    a {
        @include text-normalize-link();
        font-family: $main-font;
        opacity: 0.8;
        font-size: 0.9em;
        font-weight: 600;
        color: rgba(99, 101, 206, 0.8);
        transition: all 0.5s ease;
        cursor: pointer;

        &:hover {
            transform: scale(1.1);
            transition: all 0.5s ease;
        }
    }
}

.vertical-news-block {
    @include flexible-column-wrap();
    @include fill-all-block();
    margin-left: -3.5vw;
    margin-top: 60px;
}

.v-news-border {
    @include border-bot-smooth(85%)
}

.vertical-news {
    @include flexible-row();
    margin-top: 25px;
}

.vertical-news-datetime {
    @include flex-column();
    @include fill-all-block();
    @include text-normalize();
    @include desctiption-0-8();
    align-self: flex-start;
    margin-top: 3px;
    color: $optional-gray;
}

.vertical-news-article-data {
    @include flex-column();
    width: 100%;
    margin-left: 10px;
}

.vertival-news-article-title {
    @include text-normalize();
    @include title-1-1();
    color: $not-bright-white;
    margin-left: 10px;
}

.vertical-news-article-img-wrapper {
    @include flexible();
    @include border-radius(20px);
    margin-top: 14px;
    width: 700px;
    height: 360px;
}


.vertical-news-article-img {
    @include flexible();
    @include fill-all-block();
    @include ct-n-rp();
    background-position: center;
}

.vertical-news-article-data-preview {
    @include text-normalize();
    @include desctiption-0-8();
    height: auto;
    margin-top: 21px;
    width: 650px;
    margin: 15px 0 20px 20px;
    color: $brightless-gray;
}

.vertical-news-upload-more {
    @include flexible();
    margin-top: 25px;
    margin-left: 4.5vw;
    font-family: AventirN;

    a {
        cursor: pointer;
        color: rgba(99, 101, 206, 0.8);
        font-size: 0.8em;
        font-weight: 300;
    }
}

.bottom_padding {
    height: 50px;
}

</style>
